@import '../../styles/breakpoints';

.container {
  width: 100%;
  max-width: var(--brand-container-width, var(--container-width));
  margin-left: auto;
  margin-right: auto;

  &.branded {
    background-color: var(--color-neutral-lighter);

    @include device-large {
      padding-left: var(--size-spacing-08);
      padding-right: var(--size-spacing-08);
    }
  }
}

@include device-large {
  .grid {
    display: grid;
    grid-template-columns:
      var(--outside-gutters) 2fr var(--size-spacing-05) var(--size-spacing-11)
      1fr var(--outside-gutters);
    grid-template-rows: auto auto 1fr minmax(0, auto);
    grid-template-areas:
      'cover          cover            cover            cover            totals           totals'
      '.              title            title            .                aboutownermini   .'
      '.              features       features           .                aboutownermini   .'
      '.              main             main             .                aside            .';
  }
}

.cover {
  grid-area: cover;
  background: var(--color-white);
  border-bottom: 1px solid var(--color-neutral-600);
  position: relative;

  @include device-large {
    border-left: 1px solid var(--color-neutral-600);
  }
}

.totalsWrapper {
  background: var(--color-white);

  @include device-small {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @include device-large {
    grid-area: totals;

    max-height: 508px;

    flex-direction: column;
    justify-content: space-around;
    border-right: 1px solid var(--color-neutral-600);
    border-bottom: 1px solid var(--color-neutral-600);
    border-left: 1px solid var(--color-neutral-600);
  }
}

.callsToAction {
  display: flex;
  padding: var(--outside-gutters);
  justify-content: center;

  @include device-small {
    min-width: 370px;
    position: relative;
    flex-direction: column;
    align-items: flex-end;
    background-color: unset;
    border-top: none;
  }

  @include device-large {
    min-width: initial;
    width: 100%;
    padding-top: var(--size-spacing-03);
    padding-right: var(--size-spacing-05);
    padding-bottom: var(--size-spacing-03);
    padding-left: var(--size-spacing-05);
    align-items: center;
  }
}

.title {
  grid-area: title;
}

.pageOwner {
  position: relative;
  padding: var(--size-spacing-08) var(--size-spacing-05) 0
    var(--size-spacing-05);

  @include device-large {
    padding-left: 0;
    padding-right: 0;
  }

  &.noAvatar {
    padding-top: var(--size-spacing-05);
  }
}

.pageOwnerAvatar {
  z-index: 1;
  grid-area: profile-avatar;
  position: absolute;
  left: var(--size-spacing-04);
  transform: translateY(-50%);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.pageOwnerTitles {
  grid-area: profile-copy;
  display: flex;
  flex-direction: column;
  gap: var(--size-spacing-02);
}

.features {
  padding: calc(var(--size-spacing-06) - var(--outside-gutters))
    var(--size-spacing-05) var(--size-spacing-05) var(--size-spacing-06);
  border-bottom: 1px solid var(--color-neutral-600);
  background-color: var(--color-white);
  margin-left: 0px;

  @include device-large {
    grid-area: features;
    border-bottom: none;
    padding: var(--size-spacing-06) var(--size-spacing-05);
    display: grid;
    background-color: transparent;
    padding-left: 0;
  }
}

.causeMini {
  grid-area: aboutownermini;
}

.main {
  grid-area: main;
}

.aside {
  grid-area: aside;
  border-top: 1px solid var(--color-neutral-600);
}

.bottomWrapper {
  background: var(--color-white);
  border-top: 1px solid var(--color-neutral-600);

  @include device-large {
    padding: 0 var(--outside-gutters);
  }
}

.bottom {
  padding: var(--size-spacing-05);

  @include device-large {
    padding: var(--size-spacing-05)
      calc((100% - (var(--container-width) - (var(--outside-gutters) * 2))) / 2);
  }
}

.section {
  padding: var(--size-spacing-05);

  @include device-large {
    padding: var(--size-spacing-05) 0;
  }
}

.topBorder {
  border-top: 1px solid var(--color-neutral-600);
}

.noBorderBottom {
  border-bottom: none;
}

.hideSection {
  display: none;
}
